<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.go(-1)">学员题库管理(小程序)</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员题库列表-{{ questionInfo.questionBankName }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input
                v-model="realname"
                type="text"
                size="small"
                clearable
                placeholder="请输入题库名称"
              />
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div> -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="学员姓名"
                align="center"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="错误总数"
                align="center"
                prop="errorNum"
                show-overflow-tooltip
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="正确总数"
                align="center"
                prop="successNum"
                show-overflow-tooltip
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="答题总数"
                align="center"
                prop="totalNum"
                show-overflow-tooltip
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="答题正确率"
                align="center"
                prop="correctRate"
                show-overflow-tooltip
                min-width="100"
              ><template slot-scope="scope">
                  {{scope.row.correctRate}} %
                </template>.
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "studentQuestionList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上页传过来的题库info
      questionInfo: {}
    };
  },
  computed: {},
  created() {
    this.questionInfo = this.$route.query || {}
  },
  methods: {
    // 初始化 - 获取学员题库列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        questionBankId: this.$route.query.questionBankId
      };
      this.doFetch(
        {
          url: "/miniapp/question/learn/detail/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {},
};
</script>
<style lang="less" scoped></style>
